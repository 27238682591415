import React, { useEffect } from 'react';

const Session321 = () => {
    useEffect(() => {
            const sourceMap = {
                'google':'2',
                'bing':'3',
                'facebook':'16',
                'linkedin':'38',
                'ins321':'351',
                'no_source':'430'
            }
            var ins321_session
            try {ins321_session = sessionStorage.getItem("ins321_session")}
            catch (err) {ins321_session = null;}
            const set_session = () => {
                ins321_session = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 9);
                sessionStorage.setItem("ins321_session", ins321_session)
            }
            if (ins321_session===null) {
                document.cookie = `referrer321=${document.referrer}`
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                let source = urlParams.get('utm_source')
                let gclid = urlParams.get('gclid')
                if (!gclid) { let gclid = null}
                document.cookie = `source321=${source}`
                source = sourceMap[source]||'351'
                //document.cookie = `source_code321=${source}`
                document.cookie = `gclid=${gclid}`
                set_session()
            }

            if (sessionStorage.pagecount) {
                sessionStorage.pagecount = Number(sessionStorage.pagecount) + 1;
                pvs = sessionStorage.pagecount
            } 
            else {
                var pvs
                sessionStorage.pagecount = 1;
                pvs = sessionStorage.pagecount
            }
    })
    return(<div id="session_data"></div>)

}
 
export default Session321;
